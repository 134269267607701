



























































import { apiSupplierDel, apiSupplierLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import { Component, Vue } from 'vue-property-decorator'
import LsDialog from '@/components/ls-dialog.vue'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
  components: {
    LsDialog,
    LsPagination,
    ExportData
  }
})
export default class Supplier extends Vue {
  queryObj = {
    name: ''
  }

  pager = new RequestPaging()
  apiSupplierLists = apiSupplierLists
  getList(): void {
    this.pager.request({
      callback: apiSupplierLists,
      params: {
        ...this.queryObj
      }
    })
  }

  handleReset() {
    this.queryObj = {
      name: ''
    }
    this.getList()
  }

  handleDelete(id: number) {
    apiSupplierDel(id).then(() => {
      this.getList()
    })
  }

  created() {
    this.getList()
  }
}
